import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
import * as AuthSession from 'expo-auth-session';
import { Platform } from 'react-native';

import { _pushSnack } from '@lib';
import { _translate } from '@languageProvider';

const releaseChannel = Updates.releaseChannel || Constants.manifest.releaseChannel;
const manifestVersion = Updates.manifest.version || Constants.manifest.version;

const API_PREFIX = Constants.manifest.extra.API_PREFIX;
const API_SPECIFIC_EXTENSION = '/terminal_api';

// const API_PREFIX = 'https://72d1-82-213-250-220.ngrok.io/';

export const OAUTH = {
    discovery: {
        authorizationEndpoint: `${API_PREFIX}oauth/authorize/`,
        tokenEndpoint: `${API_PREFIX}oauth/token/`,
    },
    config: {
        clientId: 'ISOLAexyRWOQn3CTIX2vHw',
        redirectUri: window.location.origin + '/',
        codeChallengeMethod: "S256",
        scopes: ['terminal'],
    }
};

class APIService {
    constructor(api_prefix = API_PREFIX) {
        this.api_prefix = api_prefix;
        this.api_token = null;
        this.bar_id = null;
    }

    async request(route, method = 'GET', data = null, image_uri = null, query = null, extraHeaders = {}) {
        let url = this._request_url(route, query);
        let options = { method, headers: {}, credentials: 'omit' };

        if ((data !== null || image_uri)) {
            if (image_uri) {
                const form = new FormData();
                form.append('file', {
                    uri: image_uri,
                    type: "image/jpeg",
                    name: 'photo.jpeg'
                })
                options.body = form;
                options.headers['Content-Type'] = 'multipart/form-data';
            } else {
                options.body = JSON.stringify(data);
                options.headers['Content-Type'] = 'application/json';
            }
        }

        // Token based auth
        if (this.api_token !== null) {
            options.headers['Authorization'] = 'Bearer ' + this.api_token;
        }

        if (Platform.OS !== 'web') {
            options.headers['X-Reader-Client-Version'] = manifestVersion;
            options.headers['X-Reader-Client-Channel'] = releaseChannel;
        }
        options.headers = { ...options.headers, ...extraHeaders };

        let response;
        try {
            response = await fetch(url, options);
        } catch (error) {
            // Network error
            _pushSnack({
                type: 'warning',
                icon: 'warning',
                text: _translate('connectionError')
            })
        }

        try {
            // console.log(method, url, '\n', options);
            if (response.headers.get('content-type') !== 'application/json') {
                return { statusCode: response.status }
            }
            let json = await response.json();
            return { ...json, statusCode: response.status };
        } catch (error) {
            // sentry
            console.error(error);
            throw error;
        }

    }

    async request_no_cache(route, method = 'GET', data = null, image_uri = null, query = null) {
        return await this.request(route, method, data, image_uri, query, {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0',
        });
    }

    async authenticate(token) {
        this.api_token = token;
        let response = await this.request('/authenticate');
        if (response.response && response.response.place_name) {
            return {
                status: 'ok',
                placeName: response.response.place_name,
                liveEventName: response.response.live_event_name,
                deviceName: response.response.name,
            };
        } else {
            this.api_token = '';
        }
        return ({ status: 'error' });
    }

    async logOut() {
        this.api_token = null;
        this.bar_id = null;
        this.createOAuth();
        // const response = await this.request("/profile/tokens/current", "DELETE");
        // if (response.status === "ok"){
        //     // this.api_token = null This should not affect in any way.
        //     this.uuid = null
        // }
        // return response;
    }

    async barLogOut() {
        this.bar_id = null;
    }

    async get(url, query) {
        return await this.request(url, 'GET', null, null, query);
    }

    async post(url, data) {
        return await this.request(url, 'POST', data);
    }

    async put(url, data) {
        return await this.request(url, 'PUT', data);
    }

    async delete(url) {
        return await this.request(url, 'DELETE');
    }

    redirect(_url, config = {}) {
        const { origin, comeBack } = config;
        let url = origin === 'same' ? API_PREFIX + _url : _url;
        if (comeBack) {
            url += `?next=${window.location}`
        }
        window.location = url;
    }

    async createOAuth() {
        console.log('Create OAUTH');
        const request = new AuthSession.AuthRequest(OAUTH.config);
        const authUrl = await request.makeAuthUrlAsync(OAUTH.discovery);
        window.localStorage.removeItem('oauthReqData');
        window.localStorage.removeItem('oauthAccessToken');
        window.localStorage.setItem('oauthReqData', JSON.stringify(request));
        console.log('Written oauthReqData');
        window.location = authUrl;
    }

    async exchangeOAuthCode(code) {
        try {
            const oauthReqData = JSON.parse(window.localStorage.getItem('oauthReqData'));
            const accessData = await AuthSession.exchangeCodeAsync({
                ...oauthReqData,
                code,
                extraParams: {
                    code_verifier: oauthReqData.codeVerifier,
                    code_challenge_method: 'S256',
                    code_challenge: oauthReqData.codeChallenge
                }
            }, OAUTH.discovery);
            window.localStorage.setItem('oauthAccessToken', accessData.accessToken);
            service.api_token = accessData.accessToken;
        } catch (error) {
            throw ('createOAuth');
        }
    }

    _mainError(errors) {
        return errors ? (errors[0].keycode).split(':')[0] : null;
    }

    error(response) {
        if (Array.isArray(response.errors)) {
            return {
                status: 'err',
                statusCode: response.statusCode,
                mainError: this._mainError(response.errors),
                keycodes: response.errors ? response.errors.map(e => e.keycode) : null,
                payload: {
                    email: response.errors ? response.errors.email_address : null,
                }
            }
        } else {
            return {
                status: 'err',
                statusCode: response.statusCode,
                ...response.errors,
            }
        }
    }

    _request_url(route, query) {
        let url;
        if (route.startsWith('http://') || route.startsWith('https://')) {
            return route;
        } else {
            url = new URL(API_SPECIFIC_EXTENSION + route, this.api_prefix);
        }
        let params = ''
        if (query) {
            params = '?' + new URLSearchParams(query).toString();
        }
        return url.toString() + params;
    }
}

let service = new APIService();

export const renderError = (response) => ({
    status: 'err',
    statusCode: response.statusCode,
    keycode: response.errors ? response.errors.keycode : null,
    payload: {
        email: response.errors ? response.errors.email_address : null
    }
})

export const QREncodeURI = (data, raw=false) => {
    const u = new URL('api/support/qrcode', API_PREFIX);
    u.searchParams.append('key', raw ? data : JSON.stringify(data));
    u.searchParams.append('level', 'H');
    return u;
}

import * as Orders from './orders';
import * as Products from './products';
import * as PickupBars from './pickupbars';
import * as Validation from './validation';
import * as Trackings from './trackings';
export { Orders, Products, PickupBars, Validation, Trackings };

export default service;
